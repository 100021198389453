import { gql } from "@apollo/client";

const COMPANIES_QUERY = gql`
  query Companies {
    companies {
        id
        details {
           ... on CompanyDetails {
              name
              id
              companyType
          }
        }
        createdAt
        updatedAt
    }
  }
`;

const PAGINATED_COMPANIES_QUERY = gql`
  query PaginatedCompanies(
    $filters: CompanyFilterInput,
    $first: Int,
    $after: String,
    $last: Int,
    $before: String
  ) {
      paginatedCompanies(
        filters: $filters,
        first: $first,
        after: $after,
        last: $last,
        before: $before
      ) {
          totalCount
          pageInfo {
              hasNextPage
              hasPreviousPage
              endCursor
              startCursor
          }
          edges {
              cursor
              node {
                  id
          details {
              name
              companyType
              logo
              createdAt
              updatedAt
          }
          responsibles {
              id
              details{
                  firstName
                  lastName
              }
          }
          current
          createdAt
              }
          }
      }
  }
`;

const COMPANIES_SMALL_LIST = gql`
  query PaginatedCompanies(
    $filters: CompanyFilterInput,
    $first: Int,
    $after: String,
    $last: Int,
    $before: String
  ) {
      paginatedCompanies(
        filters: $filters,
        first: $first,
        after: $after,
        last: $last,
        before: $before
      ) {
          edges {
              cursor
              node {
                  id
                  details {
                      name
                      companyType
                      logo
                      createdAt
                      updatedAt
                  }
                  current
                  createdAt
              }
          }
      }
  }
`;

export { COMPANIES_QUERY as default, PAGINATED_COMPANIES_QUERY, COMPANIES_SMALL_LIST};