import { gql } from "@apollo/client";

const BRANDS_LIST_QUERY = gql`
  query BrandsList($companyId: ID, $search: String) {
    brands(companyId: $companyId, search: $search) {
      id
      name
      companyId
    }
  }
`;

const BRANDS_MAIN_VIEW_QUERY = gql`
  query Brands($companyId: ID, $responsible: Boolean) {
    brands(companyId: $companyId, responsible: $responsible) {
      id
      name
      description
      status
      logoUrl
      websiteUrl
      company {
        id
        name
      }
      responsible {
        id
        details {
          ... on PersonDetails {
            firstName
            lastName
          }
        }
        user {
          primaryEmail
        }
      }
      warehouse {
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const BRAND_DETAILS_QUERY = gql`
  query Brand($id: ID!) {
    brand(id: $id) {
      id
      name
      description
      company {
        id
        details {
          ... on CompanyDetails {
            name
            companyType
          }
        }
      }
      responsible {
        id
        details {
          ... on PersonDetails {
            firstName
            lastName
          }
        }
      }
      status
      logoUrl
      websiteUrl
      createdAt
      updatedAt
      current
      warehouse {
        id
        name
      }
    }
  }
`;

export { BRANDS_LIST_QUERY, BRANDS_MAIN_VIEW_QUERY, BRAND_DETAILS_QUERY };
