import { useState, useEffect } from "react";

import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { includes, some } from "lodash";
import { usePathname } from "next/navigation";

import { PUBLIC_ROUTES } from "@/constants/routes";
import { brandsVar, companiesVar, userDataVar } from "@/lib/local-state";
import { BRANDS_LIST_QUERY } from "@/lib/queries/brands-list";
import COMPANIES_QUERY from "@/lib/queries/companies";

function loadStoredSession(key) {
  const storedSession = localStorage.getItem(key);
  if (storedSession) {
    try {
      return JSON.parse(storedSession);
    } catch (error) {
      console.error("Error parsing session data:", error);
    }
  }
}

export function useSession(key = "mvSession") {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const parsedSession = loadStoredSession(key);

    if (parsedSession) {
      try {
        setSessionData(parsedSession);
        userDataVar(parsedSession);
      } catch (error) {
        console.error("Error al analizar datos de sesión:", error);
      }
    }

    setLoading(false);
  }, [key]);

  const saveSession = (data) => {
    localStorage.setItem(key, JSON.stringify(data));
    setSessionData(data);
    userDataVar(data);
  };

  const updateSession = (key, value) => {
    // if key is an object, we assume it contains several keys and values
    if (typeof key === "object") {
      const newSession = { ...userDataVar(), ...key };
      saveSession(newSession);
    } else {
      const newSession = { ...userDataVar(), [key]: value };
      saveSession(newSession);
    }
  };

  const clearSession = () => {
    localStorage.removeItem(key);
    setSessionData(null);
    userDataVar({});
  };

  return { sessionData, saveSession, clearSession, loading, updateSession };
}

export function useLocalStorageSession() {
  const session = useReactiveVar(userDataVar);
  const [storageSession, setStorageSession] = useState(session);

  useEffect(() => {
    if (!session || Object.keys(session).length === 0) {
      return;
    }

    if (Object.keys(session).length === 0) {
      return;
    }
    localStorage.setItem("mvSession", JSON.stringify(session));
    setStorageSession(session);
  }, [session]);

  return storageSession;
}

export function useCompanies() {
  const [getCompanies, { data }] = useLazyQuery(COMPANIES_QUERY, {
    fetchPolicy: "network-only",
  });
  const [isQueryLoading, setIsQueryLoading] = useState(false);
  const companies = companiesVar();

  useEffect(() => {
    if (!companies || companies.length === 0) {
      setIsQueryLoading(true);
      getCompanies();
    }
  }, [companies, getCompanies]);

  useEffect(() => {
    if (data && data.companies) {
      companiesVar(
        data.companies.map((company) => ({
          id: company.id,
          name: company.details.name,
        }))
      );
    }
    setIsQueryLoading(false);
  }, [data]);

  return { companies, companiesLoading: isQueryLoading };
}

export function useBrands() {
  const [getBrands, { data }] = useLazyQuery(BRANDS_LIST_QUERY);
  const [isQueryLoading, setIsQueryLoading] = useState(false);
  const { companyId = null } = useReactiveVar(userDataVar);
  const brands = brandsVar();

  useEffect(() => {
    setIsQueryLoading(true);

    if (companyId) {
      getBrands({ variables: { companyId } });
    } else {
      brandsVar([]);
      setIsQueryLoading(false);
    }
  }, [companyId, getBrands]);

  useEffect(() => {
    if (data && data.brands) {
      brandsVar(
        data.brands.map((brand) => ({ id: brand.id, name: brand.name }))
      );
    }
    setIsQueryLoading(false);
  }, [data]);

  return { brands, brandsLoading: isQueryLoading };
}

export function useSessionValidation({ router }) {
  let session = useReactiveVar(userDataVar);
  const pathname = usePathname();

  useEffect(() => {
    if (!session || Object.keys(session).length === 0) {
      session = loadStoredSession("mvSession");
      userDataVar(session);
    }

    const currentPath = router.pathname;
    if (session && session.token && currentPath === "/login") {
      router.push("/dashboard");
      return;
    }

    if (currentPath === "/login") {
      return;
    }

    if (!session?.token || Object.keys(session).length === 0) {
      const isPublicRoute = some(PUBLIC_ROUTES, (route) =>
        includes(pathname, route)
      );

      if (isPublicRoute) {
        return;
      }
      router.push("/login");
    }
  }, [router, session]);
}
