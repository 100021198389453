const CARRIER_SERVICES = {
  dhl: {
    image: "/images/carriers/dhl.svg",
    trackingUrl: "https://www.dhl.com/en/express/tracking.html?AWB=",
  },
  fedex: {
    image: "/images/carriers/fedex.svg",
    trackingUrl: "https://www.fedex.com/apps/fedextrack/?tracknumbers=",
  },
  ups: {
    image: "/images/carriers/ups.svg",
    trackingUrl: "https://www.ups.com/track?loc=en_US&tracknum=",
  },
  usps: {
    image: "/images/carriers/usps.svg",
    trackingUrl: "https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=",
  },
  globalpost: {
    image: "/images/carriers/globalpost.svg",
    trackingUrl: "https://www.globalpost.com/track/",
  },
};

export { CARRIER_SERVICES };
