import { makeVar } from "@apollo/client";

export const userDataVar = makeVar({});
export const tokenVar = makeVar(null);
export const errorVar = makeVar(null);
export const currentSectionVar = makeVar("home");
export const currentSubSectionVar = makeVar(null);
export const sidebarCollapsedVar = makeVar(true);
export const pageSizeVar = makeVar(20);
export const companiesVar = makeVar([]);
export const brandsVar = makeVar([]);
export const apiKeysVar = makeVar({
  selected: null,
});

// Session related
export const showSessionPrompt = makeVar(false);

// Processing signals
export const processingVar = makeVar(false);

// Refetch signals
export const refetchChannelsVar = makeVar(false);
export const refetchBrandsVar = makeVar(false);
export const refetchFlagsVar = makeVar(false);
export const refetchExternalIntegrationsVar = makeVar(false);

// Interaction signals
export const editModeVar = makeVar(false);

// Section Quick View Signals
export const openQuickViewVar = makeVar(null);
export const quickViewStackVar = makeVar([]);

// Orders
export const ordersSelectionVar = makeVar({});
export const orderFiltersVar = makeVar({});
export const refetchOrderVar = makeVar(false);
export const ordersAutoAddVar = makeVar(false);
export const newOrdersCountVar = makeVar(0);
export const reloadOrdersVar = makeVar(false);
export const orderLoadingVar = makeVar(false);

// Inventory
export const inventoryFiltersVar = makeVar({});
export const refetchInventoryVar = makeVar(false);

// Product 
export const productFiltersVar = makeVar({});
export const productloadingVar = makeVar(false);
export const reloadProductsVar = makeVar(false);

// ASN
export const asnFiltersVar = makeVar({});
export const refetchASNVar = makeVar(false);

// People
export const peopleFiltersVar = makeVar({});

// Warehouses
export const warehouseFiltersVar = makeVar({});

// Integrations
export const reloadIntegrationsVar = makeVar(false);

// Channels
export const channelFiltersVar = makeVar({});

// Section Title
export const sectionTitleVar = makeVar({
  title: '',
  headline: '',
  icon: null
});