import { clsx } from "clsx";
import DOMPurify from "dompurify";
import {
  Truck,
  Package,
  ShoppingCart,
  Globe,
  Store,
  Building2,
  HelpCircle,
  Box,
  MapPin,
  Building,
} from "lucide-react";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";

import { CARRIER_SERVICES } from "@/constants/asn";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const googleMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const formatDimensions = (length, width, height) => {
  if (!length && !width && !height) return '';
  
  const dimensions = [length, width, height]
    .map(dim => dim ? `${Number(dim).toFixed(2)} in` : '0.00 in')
    .join(' x ');
    
  return dimensions;
};

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

export const sanitizeHTML = (html) => {
  DOMPurify.setConfig({
    ADD_ATTR: ["target"],
    ADD_TAGS: ["a"],
    FORBID_TAGS: ["script", "style"],
    FORBID_ATTR: ["onerror", "onload", "onclick"],
  });

  return {
    __html: DOMPurify.sanitize(html),
  };
};

const formatDate = (date = null, short = true, seconds = false) => {
  if (!date) {
    return "";
  }

  const newDate = new Date(date);
  const userLocale = "en-US";

  if (isNaN(newDate)) {
    return "";
  }

  const formatOptions = short
    ? {
        year: "numeric",
        month: "short",
        day: "numeric",
      }
    : {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };

  if (!short && seconds) {
    formatOptions.second = "numeric";
    formatOptions.hourCycle = "h23";
  }

  const dateFormat = new Intl.DateTimeFormat(userLocale, formatOptions);
  return dateFormat.format(newDate);
};

export default formatDate;

export const getChannelTypeIcon = (type, size = 20) => {
  switch (type) {
    case "DISTRIBUTOR":
      return <Truck size={size} />;
    case "DROP_SHIP":
      return <Package size={size} />;
    case "ECOMMERCE":
      return <ShoppingCart size={size} />;
    case "MARKETPLACE":
      return <Globe size={size} />;
    case "RETAIL":
      return <Store size={size} />;
    case "WHOLESALE":
      return <Building2 size={size} />;
    default:
      return <HelpCircle size={size} />;
  }
};

export const getRoutingStrategyIcon = (strategy) => {
  switch (strategy) {
    case "SINGLE_SHIPPING":
      return <Box size={16} />;
    case "SHORTEST_DISTANCE":
      return <MapPin size={16} />;
    case "BUILDING_PREFERENCE":
      return <Building size={16} />;
    default:
      return <HelpCircle size={16} />;
  }
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const copyText = (text) => {
  navigator.clipboard.writeText(text);
  toast.info("Copied to clipboard");
};

/**
 * Determine if the mainview filters are empty
 * @param {Object} filters
 * @returns {boolean}
 */
export function isEmptyFilters(filters) {
  return !Object.values(filters).some((value) => value !== null);
}

export const formatCurrency = (value, currency = "USD") => {
  if (!value) {
    return null;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(value);
};

export const formatPhone = (phone) => {
  if (!phone) {
    return "";
  }
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

export const delay = (delay = 100) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const formatUnits = (value, unit = "") => {
  if (!value) {
    return "-";
  }

  const formattedValue = value.toFixed(2);
  return `${formattedValue} ${unit}`;
};

/**
 * Highlight matching text in a string.
 * @param {string} text
 * @param {string} searchTerm
 * @returns {string}
 */
export const highlightMatchingText = (text, searchTerm) => {
  if (!searchTerm) return text;
  
  const regex = new RegExp(`(${searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
  return text.replace(regex, '<span class="bg-yellow-200 dark:bg-yellow-800">$1</span>');
} 

/**
 * Format a carrier service name to a specific format.
 * @param {string} service
 * @returns {string}
 */
export const formatCarrierService = (service) => {
  if (!service) {
    return "-";
  }
  return service.replace(/(\w+).*/, "$1").toLowerCase();
};

/**
 * Get the tracking URL for a carrier service.
 * @param {string} service
 * @param {string} trackingNumber
 * @returns {string}
 */
export const getShippingCarrierTrackingUrl = (service, trackingNumber) => {
  if (!service || !trackingNumber) {
    return "-";
  }
  return `${
    CARRIER_SERVICES[formatCarrierService(service)]?.trackingUrl
  }${trackingNumber}`;
};

/**
 * Format a number to a specific format.
 * @param {number} value
 * @returns {string}
 */
export const formatNumber = (value) => {
  if (isNaN(value)) {
    return "";
  }

  return new Intl.NumberFormat().format(value);
};
